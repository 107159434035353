import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { IntlProvider, addLocaleData } from 'react-intl'

import Header from './header'
import Footer from './footer'
import '../css/custom.scss'

// Locale data
import enData from 'react-intl/locale-data/en'
import itData from 'react-intl/locale-data/it'

// Messages
import en from '../i18n/en.json'
import it from '../i18n/it.json'

const messages = { en, it }
addLocaleData([...enData, ...itData])


const Layout = ({ children, location, locale='it' }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <IntlProvider locale={locale} messages={messages[locale]}>
        <div className="container">
          <Header siteTitle={data.site.siteMetadata.title} />
          {children}
          <Footer siteTitle={data.site.siteMetadata.title} />
        </div>
      </IntlProvider>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
