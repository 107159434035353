import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Link } from './localizedLink'
import Img from 'gatsby-image'
import { injectIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'


import PropTypes from 'prop-types'

const Header = ({ intl: { locale, formatMessage, messages }}) => {
  const [open, setOpen] = useState(false);
  const [submenu, setSubmenu] = useState(false);

  const openHamburger = (e) => {
    e.preventDefault();
    setOpen(!open);
  }

  const openSubmenu = (e) => {
    e.preventDefault();
    setSubmenu(!submenu);
  }



  const { navigationJson, box1, box2, box3, logo, role } = useStaticQuery(
    graphql` {
      navigationJson {
        it {
          header{
            name
            link
            children {
              name 
              link
            }
          }
          footer{
            name
            link
          }
        }
        en {
          header{
            name
            link
            children {
              name 
              link
            }
          }
          footer{
            name
            link
          }
        }  
      }
        box1: contentfulContenutoGenerico(contentful_id: {eq: "58Q2xV6aFuhA3E0a9ZEU4X"}) {
          immagine {
            fluid(maxWidth: 600) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        } 
        box2:  contentfulContenutoGenerico(contentful_id: {eq: "6yXZJZWnAwXUYcbNxrCs5t"}) {
          immagine {
            fluid(maxWidth: 600) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
        box3:  contentfulContenutoGenerico(contentful_id: {eq: "ZdTdSpj75lcQXt0L9RyTx"}) {
          immagine {
            fluid(maxWidth: 600) {
              ...GatsbyContentfulFluid_withWebp

            }
          }
        }
        logo: file(name: {eq: "giulia_clerici"}) {
          name
          childImageSharp {
            fluid(maxWidth:240,  quality: 80) {
                ...GatsbyImageSharpFluid_noBase64
            }
          }
        }  
        role: file(name: {eq: "art-illustration"}) {
          name
          childImageSharp {
            fixed(width:280) {
                ...GatsbyImageSharpFixed_noBase64
            }
          }
        }  
      }    
    `
  );
  const openClass = open ? 'open' : '';  
  const thumb = [box1.immagine, box2.immagine, box3.immagine];  
  return (
    <div className="row">
      <div className="col-12 text-center">
        <div className="header">
          
          <div className="clearfix">
            <h1 className="header__logo">
              <Link className="header__link" to="/">
                <Img title="Giulia Clerici" alt="Giulia Clerici" fluid={logo.childImageSharp.fluid}/>
              </Link>
            </h1>
            <div className="header__illustration">
              <Img   alt="Art illustration design" fixed={role.childImageSharp.fixed}/>
            </div>
            <a href="/" className={`hamburger d-lg-none ${openClass}`} onClick={(e) => openHamburger(e)}>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </a>
           
            <div className={`navigation ${openClass}`}>
              <ul className="header__languagepicker">
                <Link activeClassName="active"  literal="true" to={`${locale==='it'?"/":"/en/"}`}><li><img alt="language" src={`/images/${locale==='it'?"IT.png":"EN.png"}`}/>{locale==='it'?'IT':'EN'}</li></Link>
                <Link activeClassName="active"  literal="true" to={`${locale==='it'?"/en/":"/"}`} ><li><img alt="language" src={`/images/${locale==='it'?"EN.png":"IT.png"}`}/>{locale==='it'?'EN':'IT'}</li></Link>
              </ul>
              <ul className="navigation__list">
              {navigationJson[locale].header.map(item => (
                <li className={`navigation__item ${(item.children && submenu)?'navigation__item--submenu':''}`} key={item.link}>
                  {item.link.startsWith('http') ? (
                      <a className="navigation__link" href={item.link} title={item.name} rel="noopener noreferrer" target="_blank">{item.name}</a> 
                  ):(
                    <>
                      <Link 
                        className={`navigation__link ${(item.children)?'navigation__link--submenu':''} ${submenu?'hover':''}`}
                        title={item.name} 
                       
                        activeClassName="navigation__link navigation__link--active" 
                        getProps={({ isPartiallyCurrent, location, href}) => {
                          //console.log(item.name,isPartiallyCurrent,href,location);
                          return (
                            (isPartiallyCurrent && (href!=='/' || location.pathname === '/') && (href !== '/en/' || location.pathname === '/en/') ) ? { className: `navigation__link navigation__link--active ${(item.children)?'navigation__link--submenu':''}` } : null
                          )  
                        }}
                        to={item.link}>
                          {item.name} 
                          {item.children && 
                            <FontAwesomeIcon width="0" className="ml-3" 
                              onClick={(e) => openSubmenu(e)}  
                              icon={faChevronDown}
                           />
                        }
                      </Link>
                      
                      {item.children && submenu &&
                          <div className="navigation__submenu">
                            <div className="navigation__submenu-container">
                              {item.children.map((subitem,index) => (
                                <div key={subitem.name} className="navigation__subpage">
                                  <Link 
                                    className="navigation__link navigation__link--small"
                                    title={subitem.name} 
                                    to={subitem.link}>
                                      <Img alt={subitem.name} className="navigation__thumb" fluid={thumb[index].fluid} />
                                      {subitem.name}
                                  </Link>
                                </div>
                              ))
                              }
                            </div>
                          </div>
                      
                      }
                    </>
                  )  
                  }
                  
                </li>
              ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default injectIntl(Header);
