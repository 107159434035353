import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { injectIntl } from 'react-intl'

import locales from '../utility/locales'
import pages from '../utility/pages'

export const INTERNAL_REGEX = new RegExp(`^/(?![/0-9])`);

const LocalizedLink = ({ to, title, literal = false, intl: { locale, formatMessage, messages }, children, ...props }) => {
  const internal = INTERNAL_REGEX.test(to);
  if (!internal) {
    // Remove activeClassName prop from external links
    const { activeClassName, ...other } = props;
    return (
      <a href={to} target="_blank"  rel="noopener noreferrer" title={title} {...other}>
        <>
          {children}
        </>
      </a>
    );
  }
  if (literal) {
    
    return (
      <GatsbyLink to={to} title={title} {...props}>
        <>
          {children}
        </>
      </GatsbyLink>
    );
  }
  let path = to;
  let localizedTo;
  // Gestione hash link
  if (to.includes('#')) {
    let route = to.substring(0, to.indexOf('#'));
    if (pages[locale](route)) {
      localizedTo = `${pages[locale](route)}${to.substring(to.indexOf('#'))}`;
    } else {
      localizedTo = to;
    }
  } else {
    localizedTo = pages[locale](to) || to;
  }

  if (!locales[locale].default && localizedTo) {
    if (localizedTo.startsWith(`/${locales[locale].path}/`)) {
      console.warn(`Trovato path localizzato con prefisso locale già presente ${localizedTo}(da ${to}): dovrebbe essere rimosso`);
      path = `/${localizedTo}`;
    } else {
      path = `/${locale}${localizedTo}`;
    }
  }

  // Traduci il titolo se c'è e se esiste una traduzione, altrimenti lascialo com'è senza warning
  const translatedTitle = title ? (messages[title] ? formatMessage({ id: title }) : title) : undefined;

  return (
    <GatsbyLink to={path} title={translatedTitle} {...props}>
      <>
        {children}
      </>
    </GatsbyLink>
  );
}

export const Link = injectIntl(LocalizedLink);
