import { Link } from './localizedLink'
import React from 'react'
import { injectIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons'
import { faInstagramSquare } from '@fortawesome/free-brands-svg-icons'
import { faStore } from '@fortawesome/free-solid-svg-icons'

const Footer = ({ siteTitle, intl: { locale, formatMessage, messages }}) => {
     return (
      

        <div className="row">
          
          <div className="col-12">
            <div className="footer">
              <div className="footer__logo">
                <Link className="footer__logolink" to="/">{siteTitle}</Link>
              </div>
              <div>
                <a href="mailto:info@giuliaclerici.com" title="Scrivi a Giulia Clerici" className="footer__mail">
                  {formatMessage({"id":"scrivimi"})}
                </a>
              </div>
              <div className="footer__social">
                {/* eslint-disable-next-line */}
                <a href="https://www.facebook.com/ricettedisegnate/" title="Facebook" rel="noopener noreferrer" target="_blank">
                  <FontAwesomeIcon icon={faFacebookSquare} />
                </a>
                {/* eslint-disable-next-line */}
                <a href="https://www.instagram.com/ricettedisegnate/" title="Instgram" rel="noopener noreferrer" target="_blank">
                  <FontAwesomeIcon icon={faInstagramSquare} />
                </a>
                {/* eslint-disable-next-line */}
                <a href="https://giuliaclerici.bigcartel.com/products" title="Shop BigCartel" rel="noopener noreferrer" target="_blank">
                  <FontAwesomeIcon icon={faStore} />
                </a>
              </div>
              <ul className="footer__list">
                <li className="footer__item">
                  {formatMessage({"id":"rightsReserved"})} &#160;|&#160;
                </li>
               
                <li className="footer__item">
                  <a href="https://www.iubenda.com/privacy-policy/21651316" className="iubenda-white iubenda-embed" title="Privacy Policy ">Privacy Policy</a>
                </li>
              </ul>
              <div>P. IVA 02750040186</div>
            </div>
          </div>
        </div>
   
    )
}

export default injectIntl(Footer)
