const en = {
  '/': '/',
  '/404/': '/404/',
  '/chi-sono/': '/about-me/',
  '/cosa-faccio/': '/what-i-do/',
  '/cosa-faccio/valorizzare-i-tuoi-prodotti/':'/what-i-do/illustrations-for-your-products/',
  '/cosa-faccio/ricette-disegnate/':'/what-i-do/illustrated-recipes/',
  '/cosa-faccio/illustrazioni-personalizzate/':'/what-i-do/customized-illustrations/',
  '/i-miei-lavori/': '/artwork/',
  '/i-miei-lavori/clienti/': '/artwork/clients/',
  '/i-miei-lavori/portfolio/': '/artwork/portfolio/',
  '/contatti/': '/contact/',
  '/privacy-policy/' : '/privacy-policy/'
}

module.exports = {
  it: path => path,
  en: path => en[path]
}
