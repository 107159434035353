import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { injectIntl } from 'react-intl'
import { StaticQuery, graphql } from 'gatsby'

function SEO({ description, meta, keywords, immagineHero, robots="index,follow", title,forcetitle=false, intl: {locale, formatMessage, messages} }) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription =
          description || data.site.siteMetadata.description;
        let ogImage =  (immagineHero && immagineHero.fluid && immagineHero.fluid.src) || data.shareImage.fixed.src;  
        ogImage = ogImage && !ogImage.startsWith('https')?'https:'.concat(ogImage):ogImage;
        return (
          <Helmet
            htmlAttributes={{
              lang: locale,
            }}
            title={formatMessage({"id": title})}
            titleTemplate={forcetitle?formatMessage({"id": title}):`${formatMessage({"id": title})} | ${data.site.siteMetadata.title}`}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                name: `robots`,
                content: robots,
              },
              {
                property: `og:title`,
                content: title,
              },
              {
                property: `og:image`,
                content: ogImage,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                name: `twitter:card`,
                content: `summary`,
              },
              {
                name: `twitter:creator`,
                content: data.site.siteMetadata.author,
              },
              {
                name: `twitter:title`,
                content: title,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
            ]
              .concat(
                keywords.length > 0
                  ? {
                      name: `keywords`,
                      content: keywords.join(`, `),
                    }
                  : []
              )
              .concat(meta)}
          />
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: `it`,
  meta: [],
  keywords: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

export default injectIntl(SEO);

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
    shareImage: contentfulAsset(contentful_id: {eq: "1M2IaNEhLNwKJ8Cnyo4tuj"}) {
      fixed(width: 1460, quality: 80) {
        src
      }
    }
  }
`
